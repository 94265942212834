<template>
<div id="home">
  <section id="hero">
    <swiper id="hero-swiper" :slides-per-view="1">
      <swiper-slide>
        <div class="slide">
          <picture>
            <source srcset="@/assets/home_header_1.jpg" media="(min-width: 768px)">
            <img src="@/assets/home_header_mobile_1.jpg" class="img-fluid w-100" alt="">
          </picture>
          <div class="overlay inview slow animated">
            <p class="t-shadow mb-0">We are an <span class="red">award-winning</span> team of AAA game marketing artists and blockchain experts who are bringing over <span class="red">18 years of success</span> to the NFT gaming space.</p>
          </div>
        </div>
      </swiper-slide>
      <div id="social-overlay">
        <div class="mx-auto mt-3">
          <div class="join-overlay mb-2"><img src="@/assets/join_txt.png" class="img-fluid" alt="Join Community"></div>
          <Social />
        </div>
      </div>
    </swiper>
  </section>
  <section id="home-grid-section" class="container-fluid px-0">
    <div class="row no-gutters">
      <div class="grid-main col-md-6 text-center">
        <div class="mask inview animated" data-ease="fadeIn">
          <div class="inner">
            <h2 class="mb-0 inview slow animated">Clementine's Nightmare</h2>
            <div class="inview slow animated delay-1">
              <a class="btn btn-theme" href="http://clementinesnightmare.io/" target="_blank" rel="noopener noreferrer">Explore the Neighborhood</a>
              <h3><b class="red">AAA Design</b> Meets Unquely Entertaining <b class="red">NFT Hero Battles.</b></h3>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-stacked col-md-6">
        <div class="upper d-flex justify-content-center align-items-center text-center">
          <div class="wrapper">
            <h2 class="inview slow animated">Meet the Team</h2>
            <router-link class="btn btn-theme my-4 inview slow animated delay-1" to="/meet-the-team">Learn More</router-link>
          </div>
        </div>
        <div class="lower d-flex justify-content-center align-items-center">
          <div class="wrapper w-60">
            <h2 class="text-center inview slow animated">About</h2>
            <div class="inview slow animated delay-1">
              <p>Creatively led by the branding powerhouse behind over 100 iconic AAA gaming franchises and over 1,500 games successfully brought to market, as well as experienced blockchain engineers who have focused on open-source, privacy,
                infrastructure and getting cryptocurrency into the hands of the masses.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section v-if="live" id="home-beyond-section" class="container-fluid">
    <div class="wrapper w-60 mx-auto">
      <div class="content text-center text-uppercase inview slow animated">
        <div class="icon"><img src="@/assets/skull_icon.png" class="img-fluid" alt="BEYOND THE NEIGHBORHOOD" /></div>
        <h2 class="mb-4">BEYOND THE NEIGHBORHOOD</h2>
        <p class="pt-2 inview slow animated delay-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
          ut aliquip ex ea commodo
          consequat. uisaut
        </p>
      </div>
    </div>
  </section>
  <section id="home-welcome-section">
    <div class="wrapper container mx-auto">
      <div class="content text-center text-uppercase">
        <div class="logo inview slow animated"><img src="@/assets/welcome_logo.png" class="img-fluid" alt="Welcome to our Neighborhood" /></div>
        <div class="swiper-block">
          <swiper id="feed-swiper" :slides-per-view="3" :space-between="40" :options="swiperFeedOption" :breakpoints='{
          "300": {
            "slidesPerView": 1.5,
            "spaceBetween": 20
          },
          "768": {
            "slidesPerView": 2,
            "spaceBetween": 30
          },
          "992": {
            "slidesPerView": 3
          }
        }'>
            <swiper-slide v-for="(tweet, index) in this.tweets" :key="index" :virtualIndex="index">
              <div class="slide h-100">
                <div class="card h-100">
                  <div class="twitter-icon"><img class="img-fluid" src="@/assets/social_icon.jpg" alt="PixelatedInk" /></div>
                  <div class="headline"><b>PixelatedInk</b> : <small class="muted">{{ new Date(tweet.created_at).toDateString() }}</small><br><small><a href="https://twitter.com/pixelatedinkco" target="_blank"
                        rel="noopener noreferrer">@pixelatedinkco</a></small>
                  </div>
                  <div class="card-body">
                    <p><a v-if="tweet.entities.urls" :href="`${tweet.entities.urls[0].url}`" target="_blank" rel="noopener noreferrer">{{tweet.text}}</a></p>
                  </div>
                  <div class="card-footer">
                    <div class="metrics d-flex justify-content-center">
                      <div class="icon reply"><svg viewBox="0 0 24 24" aria-hidden="true" class="r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-1hdv0qi">
                          <g>
                            <path
                              d="M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z">
                            </path>
                          </g>
                        </svg><span>{{tweet.public_metrics.reply_count}}</span></div>
                      <div class="icon retweet"><svg viewBox="0 0 24 24" aria-hidden="true" class="r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-1hdv0qi">
                          <g>
                            <path
                              d="M23.77 15.67c-.292-.293-.767-.293-1.06 0l-2.22 2.22V7.65c0-2.068-1.683-3.75-3.75-3.75h-5.85c-.414 0-.75.336-.75.75s.336.75.75.75h5.85c1.24 0 2.25 1.01 2.25 2.25v10.24l-2.22-2.22c-.293-.293-.768-.293-1.06 0s-.294.768 0 1.06l3.5 3.5c.145.147.337.22.53.22s.383-.072.53-.22l3.5-3.5c.294-.292.294-.767 0-1.06zm-10.66 3.28H7.26c-1.24 0-2.25-1.01-2.25-2.25V6.46l2.22 2.22c.148.147.34.22.532.22s.384-.073.53-.22c.293-.293.293-.768 0-1.06l-3.5-3.5c-.293-.294-.768-.294-1.06 0l-3.5 3.5c-.294.292-.294.767 0 1.06s.767.293 1.06 0l2.22-2.22V16.7c0 2.068 1.683 3.75 3.75 3.75h5.85c.414 0 .75-.336.75-.75s-.337-.75-.75-.75z">
                            </path>
                          </g>
                        </svg><span>{{tweet.public_metrics.retweet_count}}</span></div>
                      <div class="icon likes"><svg viewBox="0 0 24 24" aria-hidden="true" class="r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-1hdv0qi">
                          <g>
                            <path
                              d="M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z">
                            </path>
                          </g>
                        </svg><span>{{tweet.public_metrics.like_count}}</span></div>
                      <div class="icon share"><svg viewBox="0 0 24 24" aria-hidden="true" class="r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-1hdv0qi">
                          <g>
                            <path
                              d="M17.53 7.47l-5-5c-.293-.293-.768-.293-1.06 0l-5 5c-.294.293-.294.768 0 1.06s.767.294 1.06 0l3.72-3.72V15c0 .414.336.75.75.75s.75-.336.75-.75V4.81l3.72 3.72c.146.147.338.22.53.22s.384-.072.53-.22c.293-.293.293-.767 0-1.06z">
                            </path>
                            <path
                              d="M19.708 21.944H4.292C3.028 21.944 2 20.916 2 19.652V14c0-.414.336-.75.75-.75s.75.336.75.75v5.652c0 .437.355.792.792.792h15.416c.437 0 .792-.355.792-.792V14c0-.414.336-.75.75-.75s.75.336.75.75v5.652c0 1.264-1.028 2.292-2.292 2.292z">
                            </path>
                          </g>
                        </svg><span>{{tweet.public_metrics.quote_count}}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev" @click="slidePrev"></div>
          <div class="swiper-button-next" @click="slideNext"></div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import helpers from '@/scripts/helpers.js'
import Social from '@/components/Social.vue'
import SwiperCore, {
  Navigation
} from 'swiper';
import {
  Swiper,
  SwiperSlide
} from 'swiper/vue';
SwiperCore.use([Navigation]);
export default {
  name: 'Home',
  components: {
    Swiper,
    SwiperSlide,
    Social
  },
  data: function() {
    return {
      live: false,
      tweets: [],
      swiperFeedOption: {
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  mounted() {
    helpers.initWaypoint();
    this.fetch_request();
  },
  methods: {
    slidePrev() {
      this.mainSwiper.slidePrev();
    },
    slideNext() {
      this.mainSwiper.slideNext();
    },
    fetch_request() {
      const feed_url = 'https://clementinesnightmare.io/json/twitter_api.php';
      const config = {
        method: "GET",
        mode: 'cors',
        headers: {}
      }
      fetch(feed_url, config).then((response) => {
          return response.json()
        })
        .then((jsonData) => {
          this.tweets = jsonData.data;
        })
        .catch(e => {
          console.log(e);
        })
    }
  }
}
</script>
<style lang="scss" scoped>
#hero-swiper {
    .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        color: #fff;
        width: 100%;
        padding: 3vw;
        max-width: 46rem;
        font-size: 2rem;
        text-transform: uppercase;
        background: rgba(0,0,0,0.5);
        @media(max-width: 1399px) {
            font-size: 1.8rem;
        }
        @media(max-width: 991px) {
            padding: 1.5rem 2rem;
        }
        @media(max-width: 767px) {
            font-size: 1.2rem;
        }
        .slide-inview {
            opacity: 0;
            &.fadeInUp {
                opacity: 1;
            }
        }
    }
}
</style>
